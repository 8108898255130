import {format, utcToZonedTime, zonedTimeToUtc} from "date-fns-tz";
import {differenceInDays, differenceInHours, differenceInMinutes, parseISO} from "date-fns";
import classNames from "classnames";
import {t} from 'i18next'

export const DateTimeFormatter = (dateTime: string): string => {
  return format(utcToZonedTime(parseISO(dateTime), "UTC"), "HH:mm:ss dd-MM-yyyy", {timeZone: "UTC"});
};

export const DateTimeFormatterIso = (dateTime: string): string => {
  return format(utcToZonedTime(parseISO(dateTime), "UTC"), "yyyy-MM-dd HH:mm:ss", {timeZone: "UTC"}).replace(" ", "T");
};

export const DateFormatter = (dateTime: string): string => {
  return format(utcToZonedTime(parseISO(dateTime), "UTC"), "dd-MM-yyyy", {timeZone: "UTC"});
};

export const DateFromDateFormatter = (date: Date): string => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());

  return day + "-" + month + "-" + year;
};

export const ImageDownloadStringFormatter = (project: string, camera: string, dateTime: string): string => {
  return `${project.replace(/\s/g, "")}-${camera.replace(/\s/g, "")}-${format(
    utcToZonedTime(parseISO(dateTime), "UTC"),
    "yyyyMMdd-HHmmss",
    {timeZone: "UTC"},
  )}.jpg`;
};

export const VideoDownloadStringFormatter = (project: string, camera: string, dateTime: string): string => {
  return `${project.replace(/\s/g, "")}-${camera.replace(/\s/g, "")}-${format(
    utcToZonedTime(parseISO(dateTime), "UTC"),
    "yyyyMMdd",
    {timeZone: "UTC"},
  )}.mp4`;
};

// works reliably, could maybe be prettier
export const CalendarDateToDailyDateStringFormatter = (date: Date): string => {
  const dateWithoutOffset = new Date().setTime(date.getTime() - date.getTimezoneOffset() * 60000);
  return format(dateWithoutOffset, "yyyy-MM-dd", {timeZone: "UTC"}) + "T12:00:01";
};

// works reliably, could maybe be prettier
export const timeDeltaSinceLastRecording = (datetime: string) => {
  const tzStripped = format(utcToZonedTime(parseISO(datetime), "UTC"), "yyyy-MM-dd HH:mm:ss", {timeZone: "UTC"});
  const imgUTC = zonedTimeToUtc(tzStripped, "Europe/Berlin");
  const current = new Date();
  if (Math.abs(differenceInMinutes(current, imgUTC)) <= 90 ) {
    return t('admin:minuteWithCount', {count: differenceInMinutes(current, imgUTC)});
  }  else if (Math.abs(differenceInHours(current, imgUTC),) <= 36) {
    return t('admin:hourWithCount', {count: differenceInHours(current, imgUTC)});
  } else {
    return t('admin:dayWithCount', {count: differenceInDays(current, imgUTC)});
  }
};

// this one is clean
export const timeDeltaColumnClassName = (datetime: string, active: boolean) => {
  const tzStripped = format(utcToZonedTime(parseISO(datetime), "UTC"), "yyyy-MM-dd HH:mm:ss", {timeZone: "UTC"});
  const imgUTC = zonedTimeToUtc(tzStripped, "Europe/Berlin");
  const minutes = differenceInMinutes(new Date(), imgUTC);
  return classNames("border px-8 py-4 text-center", {
    "bg-yellow-500": minutes > 60 && minutes <= 180 && active,
    "bg-orange-500": minutes > 180 && minutes <= 1440 && active,
    "bg-red-500": minutes > 1400 && active,
  });
};
