import apiFetch from "../../../services/dataAccess";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {ImageUrlFormatter} from "../../../services/urlFormatter";
import {useTranslation} from "react-i18next";
import {DateTimeFormatter} from "../../../services/stringFormatter";

interface SearchProps {
  query: string;
}

interface SearchResult {
  id: string;
  name: string;
  project: string;
  projectName: string;
  cameraName: string;
  cameraNumber: number;
  timestamp: string;
}

export const Search: React.FC<SearchProps> = ({query}) => {
  const {t} = useTranslation();
  const [results, setResults] = useState<Array<SearchResult>>([]);

  useEffect(() => {
    apiFetch(`/search?q=${query}`)
      .then((r) => r.json())
      .then((r) => {
        setResults(r.recordings);
      });
  }, [query]);

  return (
    <div className="overflow-y-auto" style={{height: "calc(80vh - 4rem)"}}>
      {results.length === 0 && <p className="text-white text-xl">{t("searchNoResults")}</p>}
      <ul className="bg-panterra-900 text-panterra-50">
        {results.map((r, i) => (
          <li key={r.id} className={`${i % 2 ? "bg-panterra-700" : ""} sm:w-full`}>
            <Link
              reloadDocument={true}
              to={`/projects/${r.project}/${r.cameraNumber}/${r.timestamp.replace("+00:00", "")}`}
            >
              <img
                className="h-32 hover:w-auto hover:h-auto md:h-8"
                src={ImageUrlFormatter(r.project, `${r.cameraNumber}`, r.timestamp, "small")}
                alt=""
              />{t('searchHit')}: Tag "{r.name
                .split(new RegExp(`(${query})`, "gi"))
                .map((part, index) =>
                  part.toLowerCase() === query.toLowerCase() ? <b className="text-red-500">{part}</b> : part,
                )}"
                <br/>
              {r.projectName} - {r.cameraName} {DateTimeFormatter(r.timestamp)} <br />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
