import {Dialog, Transition} from "@headlessui/react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {Fragment, useState} from "react";
import Tippy from "@tippyjs/react";
import {useTranslation} from "react-i18next";
import {Search} from "../projects/Search/Search";
import {CustomButton} from "../shared/CustomButton/CustomButton";

export const SearchMenu = () => {
  const {t} = useTranslation();
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.trim().length === 0) {
      setQuery('');
      return;
    }
    setQuery(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // Perform the search logic here, e.g., call an API or update the state

    if (query.length === 0) {
      return;
    }
    setIsOpen(true);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSubmit(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  return (
    <>
      <Tippy content={t('tooltip:search')} delay={[1000, 200]}>
        <div className="w-full mx-1 rounded-md p-2 hover:bg-gray-600 flex items-center">
          <form onSubmit={handleSubmit} className="flex items-">
            <input
              type="text"
              name="query"
              value={query}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="px-2 relative w-full cursor-default rounded-lg text-black bg-white text-left shadow-md focus:outline-none"
              placeholder={t('search')}
            />
            <button type="submit">
              <MagnifyingGlassIcon className="h-7 w-7" />
            </button>
          </form>
        </div>
      </Tippy>

      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full p-16 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="group relative w-full transform overflow-hidden rounded-lg bg-panterra-800 p-5 text-left shadow-xl transition-all">
                  <div className="flex items-center justify-between pb-3">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {t('serchResultFor')}: <span className="text-red-500">{query}</span>
                    </Dialog.Title>
                    <CustomButton
                      action={() => setIsOpen(false)}
                      iconProps={{
                        icon: "XMarkIcon",
                      }}
                    />
                  </div>
                  <Search query={query} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};
