import React, {useState} from "react";
import {Popover} from "@headlessui/react";
import {Icon} from "../Icon/Icon";
import {useTranslation} from "react-i18next";
import {Fragment, useEffect} from "react";
import apiFetch from "../../../services/dataAccess";
import {Listbox, Transition} from "@headlessui/react";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";
import Tippy from "@tippyjs/react";

type Props = {
  filterTags?: boolean;
  setFilterTags?: (filterTags: boolean) => void;
  filteredTags: Tag[];
  setFilteredTags: (filteredTags: []) => void;
};

type Tag = {
  id: number;
  text: string;
  color: string;
  className: string;
};

export const TagFilter = (props: Props) => {
  const {t} = useTranslation();

  const reset = () => {
    if (props.filterTags === undefined) {
      return;
    }

    props.setFilterTags && props.setFilterTags(false);

    props.setFilteredTags([]);
  };

  const [availableTags, setAvailableTags] = useState<Tag[]>([]);
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiFetch(`/tags`);
        const data = await response.json();
        setAvailableTags(
          data.map((tag: any) => {
            return {
              id: `${tag.id}`,
              text: tag.name,
              color: tag.tagColor.name,
              className: `bg-${tag.tagColor.name}-500`,
            };
          }),
        );
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, []);

  return (
    <Popover as={React.Fragment}>
      <Tippy content={t("tooltip:tagFilter")} delay={[1000, 200]}>
      <Popover.Button as={React.Fragment}>
        <div className={"absolute right-22 top-0 md:top-8 z-10"}>
          <button className="m-1 block rounded-md p-1 bg-panterra-800 hover:bg-panterra-700">
            <Icon iconClassName={"text-white w-7 h-7"} icon={"TagIcon"}></Icon>
          </button>
        </div>
      </Popover.Button>
      </Tippy>
      <Popover.Panel className={"absolute right-14 top-24 z-10 p-2 rounded bg-panterra-50 text-panterra-900"}>
        <div>
          <div className="border-2 p-2 rounded-md">
           <div>
              <input
                type="checkbox"
                checked={props.filterTags}
                onChange={() => props.setFilterTags && props.setFilterTags(!props.filterTags)}
              />{" "}
              {t("filterTags")}
            </div>
          </div>
          <div className="border-2 p-2 rounded-md">
            <label>{t('tagFilterList')}</label>
            <Listbox value={props.filteredTags} onChange={props.setFilteredTags} multiple disabled={props.filterTags}>
              <div className="relative mt-1">
                <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                  <span className="block truncate">
                    {props.filteredTags.map((item: Tag) => (
                      <span className={`m-1 p-1 rounded-lg bg-${item.color}-500`}>{item.text}</span>
                    ))}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                    {availableTags.map((tag, tagIdx) => (
                      <Listbox.Option
                        key={tagIdx}
                        className={({active}) =>
                          `bg-${tag.color}-500 relative cursor-default select-none py-1 pl-10 pr-4 text-xs ${
                            active ? `italic text-white` : `text-black-500`
                          }`
                        }
                        value={tag}
                      >
                        {({selected}) => (
                          <>
                            <span className={`block truncate ${selected ? "font-medium" : "font-normal"}`}>
                              {tag.text}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-black-500">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <button
            onClick={() => reset()}
            className={"mx-auto block p-2 mt-2 border-2 text-white rounded-md bg-panterra-800 hover:bg-panterra-700"}
          >
            {t("resetCalendar")}
          </button>
        </div>
      </Popover.Panel>
    </Popover>
  );
};
