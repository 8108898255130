import { useContext, useEffect, useState } from "react";
import { ConciseRecording, RecordingTag } from "../../types/types";
import { useTranslation } from "react-i18next";
import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";
import { Icon } from "../shared/Icon/Icon";
import { CustomColorListbox } from "../shared/CustomColorListbox";
import apiFetch from "../../services/dataAccess";
import { useWindowWidth } from '@react-hook/window-size';
import { UserConfigurationContext } from "../../context/UserConfigurationProvider";
import Tippy from "@tippyjs/react";

interface ImageTagProps {
  recording: ConciseRecording;
  updateRecording: (recording: ConciseRecording) => void;
  keyboardActiveHandler?: (active: boolean) => void;
}

/**
 * Tag interface, copied from SingleTag
 */
interface Tag {
  id: string;
  className: string;
  [key: string]: string;
}

export const ImageTag = (props: ImageTagProps) => {
  const { isPtAdmin } = useContext(UserConfigurationContext);

  const { t } = useTranslation();
  const screenWidth = useWindowWidth();

  const [tags, setTags] = useState<Array<Tag>>(props.recording.tags ?
    props.recording.tags.map((recordingTag: RecordingTag): Tag => {
    return {
      id: `${recordingTag.id}`,
      text: recordingTag.text,
      className: `bg-${recordingTag.color}-500`,
      color: recordingTag.color,
    };
  }):[]);

  const fetchTags = async () => {
    try {
      const response = await apiFetch(`/tags${isPtAdmin ? '' : '?isPublic=true'}`);
      const data = await response.json();
      setSuggestions(data.map((tag: any) => {
        return {
          id: `${tag.id}`,
          text: tag.name,
          color: tag.tagColor.name,
          className: `bg-${tag.tagColor.name}-500`,
        };
      }));
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };
  /*
  const suggestions = [
    { id: '5', text: 'Test', className: '' },
    { id: '6', text: 'Demo', className: '' },
    { id: '7', text: 'panTerra', className: 'bg-orange-300' },
    { id: '8', text: 'Best of', className: 'text-red-500 bg-panterra-300' },
  ];
  */
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiFetch(`/tags${isPtAdmin ? '' : '?isPublic=true'}`);
        const data = await response.json();
        setSuggestions(data.map((tag: any) => {
          return {
            id: `${tag.id}`,
            text: tag.name,
            color: tag.tagColor.name,
            className: `bg-${tag.tagColor.name}-500`,
          };
        }));
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

    fetchTags();
  }, [isPtAdmin]);

  const [tagColors, setTagColors] = useState([{ id: 1, name: 'orange' }]);
  const colors = tagColors.map((tagColor: any) => {
    return { value: tagColor.name, name: tagColor.name.charAt(0).toUpperCase() + tagColor.name.slice(1) };
  });
  useEffect(() => {
    const fetchTagColor = async () => {
      try {
        const response = await apiFetch(`/tag_colors`);
        const data = await response.json();
        setTagColors(data);
      } catch (error) {
        console.error("Error fetching tag colors:", error);
      }
    };

    fetchTagColor();
  }, []);

  useEffect(() => {
    if (!props.recording.tags || props.recording.tags.length === 0) {
      setTags([]);

      return;
    }
    setTags(props.recording.tags.map((recordingTag: RecordingTag): Tag => {
      return {
        id: `${recordingTag.id}`,
        text: recordingTag.text,
        className: `bg-${recordingTag.color}-500`,
        color: recordingTag.color,
      };
    }));
  }, [props.recording.tags]);

  const [color, setColor] = useState({ value: 'orange', name: 'Orange' });

  const handleAddition = (tag: Tag) => {
    if (tags.some(existingTag => existingTag.text === tag.text)) {
      return;
    }

    const addTags = (tag: Tag) => {
      tag.id = `${tag.id}`;
      tag.text = tag.name;
      tag.color = tag.tagColor;
      if (!tag.className) {
        tag.className = `bg-${tag.color}-500`;
      }

      setTags([...tags, tag]);
      if (!props.recording.tags) {
        props.recording.tags = [];
      }
      props.recording.tags.push({ id: parseInt(tag.id), text: tag.text, color: tag.color });
      props.updateRecording(props.recording);
    };

    apiFetch(`/recordings/${props.recording.id}/tags`, {
      method: 'POST',
      body: JSON.stringify({
        name: tag.text,
        color: color.value,
      })
    }).then((response) => {
      if (!response.ok) {
        return;
      }
      response.json().then((newTag) => {
        addTags(newTag);
        if (isPtAdmin) {
          fetchTags();
        }
      });
    }).catch((error) => {
      if (error.cause.status === 403) {
        console.log('You are not authorized to add tags');
      }
    });
  };

  const handleDelete = (i: number) => {
    const tag = tags[i];
    apiFetch(`/recordings/${props.recording.id}/tags/${tag.id}`, {
      method: 'DELETE',
    }).then((response) => {
      if (!response.ok) {
        return;
      }
      setTags(tags.filter((tag, index) => index !== i));
      props.recording.tags.splice(i, 1);
        props.updateRecording(props.recording);
    }).catch((error) => {
      // silence error
    });
  }

  const renderSuggestion = (item: Tag, query: string) => <div className={item.className}>{item.text}</div>;

  return (
    <div className="w-full flex flex-row border border-panterra-500 bg-panterra-900 text-black rounded-md relative">
      <Tippy content={t('tooltip:tags')} delay={[1000, 200]}>
        <span className="py-1 px-2 m-1">
          <Icon icon="TagIcon" />
        </span>
      </Tippy>
      <span style={{ width: 'calc(100% - 19.5rem)' }}>
        <ReactTags
          allowDeleteFromEmptyInput={false}
          maxTags={10}
          autoFocus={false}
          tags={tags}
          suggestions={suggestions}
          placeholder={t('tagInputPlaceholder')}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
          inputFieldPosition="top"
          maxLength={20}
          editable={false}
          readOnly={false}
          allowDragDrop={false}
          separators={[SEPARATORS.ENTER, SEPARATORS.TAB, SEPARATORS.COMMA]}
          renderSuggestion={renderSuggestion}
          minQueryLength={isPtAdmin ? 1: -1}
          handleInputFocus={(value, e) => props.keyboardActiveHandler && props.keyboardActiveHandler(false)}
          handleInputBlur={(value, e) => props.keyboardActiveHandler && props.keyboardActiveHandler(true)}
          classNames={{
            tags: 'w-full text-xs text-white relative',
            tagInput: 'absolute -right-40',
            tagInputField: `bg-${color.value}-500 rounded-md py-1 px-2 m-1 placeholder-gray-700`,
            selected: screenWidth < 470 ? 'py-10' : 'py-2' ,  // ml-auto
            tag: 'whitespace-nowrap rounded-md py-1 px-1 m-1',
            // https://github.com/react-tags/react-tags/issues/977#issuecomment-2276804051
            remove: 'ReactTags__remove ml-2 cursor-pointer text-red-500 hover:text-red-700',
            suggestions: 'absolute -bottom-1 my-8 overflow border border-panterra-500 bg-panterra-900 text-white text-xs rounded-md py-1 px-2 m-1',
            activeSuggestion: 'text-black bg-panterra-300',
            editTagInput: 'editTagInputClass',
            editTagInputField: 'editTagInputField',
            clearAll: 'clearAllClass',
          }}
        />
      </span>
      {isPtAdmin &&
        <span className="px-1 text-xs top-0 absolute right-0" style={{  width: '6.0rem' }}>
          <CustomColorListbox values={colors} value={color} setValue={setColor} />
        </span>
    }
    </div>
  );
};
