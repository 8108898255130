import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'


interface ListboxValue {
  value: any;
  name: string;
}

interface CustomListboxProps {
  values: ListboxValue[];
  value: ListboxValue;
  setValue: (value: ListboxValue) => void;
}

export const CustomColorListbox: React.FC<CustomListboxProps> = ({ values, value, setValue}) => {

  return (
    <Listbox value={value} onChange={setValue} by="name">
      <div className="relative mt-1">
        <Listbox.Button className={`opacity-100 relative cursor-default rounded-lg py-1 pl-3 pr-10 bg-${value.value}-500 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300`}>
          <span className="block truncate">{value.name}</span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronUpDownIcon
              className="h-3 w-3"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className={`z-10 absolute -bottom-1 my-8 mt-0 max-h-60 overflow-auto rounded-md bg-${values[0].value}-500 py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none`}>
            {values.map((item, idx) => (
              <Listbox.Option
                key={idx}
                className={({ active }) =>
                  `relative text-xs cursor-default select-none py-1 pl-10 pr-4 bg-${item.value}-500 ${
                    active ? 'text-black' : 'text-gray-900'
                  }`
                }
                value={item}
              >
                {({ selected }) => (
                  <>
                    <span
                      className={`block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {item.name}
                    </span>
                    {selected ? (
                      <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-grey-600">
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

