import {Logo} from "./Logo";
import {UserMenu} from "./UserMenu";
import {SearchMenu} from "./SearchMenu";
import {OffCanvas} from "./OffCanvas";
import {ProjectLogos} from "./ProjectLogos";
import { LanguageMenu } from "./LanguageMenu";

export const Navbar = () => {
  return (
    <div className={"bg-panterra-800"}>
      <div className={"container mx-auto px-2"}>
        <div className={"v-base flex h-16 items-center justify-between"}>
          {/* Menu button*/}
          <OffCanvas />

          {/* Logo */}
          <div className={"flex-column flex items-center gap-1 h-1 sm:gap-8 xs:flex"}>
            <Logo mayBeHidden={true} />
            <ProjectLogos />
          </div>

          {/* Profile dropdown */}
          <div className="flex">
            <SearchMenu />
            <LanguageMenu />
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};
