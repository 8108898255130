/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import apiFetch from "../../../services/dataAccess";
import { useNavigate, useParams } from "react-router";
import { ImageUrlFormatter } from "../../../services/urlFormatter";
import { DateFormatter, DateTimeFormatter, DateTimeFormatterIso, ImageDownloadStringFormatter } from "../../../services/stringFormatter";
import { ProjectsContext } from "../../../context/ProjectsProvider";
import { ConciseRecording, ProjectAndCamParams } from "../../../types/types";
import { UserConfigurationContext } from "../../../context/UserConfigurationProvider";
import { getNearestTimestampIndex } from "../../../services/getNearestTimestamp";
import { SelectedTimestampContext } from "../../../context/SelectedTimestampProvider";
import { Pageloader } from "../../shared/Pageloader/Pageloader";
import { CompareImagesContext } from "../../../context/CompareImagesProvider";
import { CustomButton } from "../../shared/CustomButton/CustomButton";
import ReactSlider from "react-slider";
import Tippy from "@tippyjs/react";
import { CalendarDateTimeContext } from "../../../context/CalendarDateTimeProvider";
import { Calendar } from "../../shared/Calendar/Calendar";
import { TagFilter } from "../../shared/Calendar/TagFilter";
import { sub } from "date-fns";
import { SelectedImages } from "./SelectedImages";
import { ImageFooter } from "../../shared/ImageFooter/ImageFooter";
import { ProjectBrandingClientLogo } from "../../shared/ProjectBrandingClientLogo/ProjectBrandingClientLogo";
import { ProjectBrandingPTLogo } from "../../shared/ProjectBrandingPTLogo/ProjectBrandingPTLogo";
import { StackToolbar } from "./StackToolbar";
import { HorizontalSlider } from "./HorizontalSlider";
import { SwipeEventData, useSwipeable } from "react-swipeable";
import { useTranslation } from "react-i18next";
import { ImageTag } from "../../shared/ImageTag";

const numberOfStackImages = 12;

export const ImageStack = () => {
  const navigate = useNavigate();

  const [ handleKeyActive, setHandleKeyActive ] = useState<boolean>(true);

  const { projectName, cameraNumber, timestamp } = useParams() as ProjectAndCamParams;
  const { t } = useTranslation();

  const { isPtAdmin } = useContext(UserConfigurationContext);
  const { state: projects } = useContext(ProjectsContext);
  const { selectedTimestamp, setSelectedTimestamp } = useContext(SelectedTimestampContext);
  const { startDate, endDate, startTime, endTime } = useContext(CalendarDateTimeContext);
  const { compareImages, showCompareImages, addCompareImage, removeCompareImage, clearCompareImages } =
    useContext(CompareImagesContext);

  const [recordings, setRecordings] = useState<ConciseRecording[]>([]);
  const [sliderIndex, setSliderIndex] = useState<number>(-1);
  const [calendarStartDate, setCalendarStartDate] = useState<Date>();
  const [calendarEndDate, setCalendarEndDate] = useState<Date>();
  const [filterTags, setFilterTags] = useState<boolean>(false);
  const [filteredTags, setFilteredTags] = useState<[]>([]);

  const project = projects[projectName];
  const camera = project.cameras[cameraNumber];

  // Fetch recordings once
  useEffect(() => {
    apiFetch(`cameras/${camera.id}/recordings/concise`)
      .then((r) => r.json())
      .then((r) => {
        setRecordings(r);
      });
  }, [projectName, cameraNumber]);

  // After recordings were fetched, find nearest cached timestamp or set on most current image
  useEffect(() => {
    if (!recordings.length) return;

    const startingTimestamp = selectedTimestamp || timestamp || '';
    const startIndex: number = getNearestTimestampIndex(recordings, startingTimestamp);
    gotoRecording(startIndex);

    //calculate start and end dates for the calendar
    const minutes = new Date().getTimezoneOffset();
    const startTime = sub(new Date(recordings[recordings.length - 1].timestamp), { minutes });
    const endTime = sub(new Date(recordings[0].timestamp) as Date, { minutes });

    setCalendarStartDate(startTime);
    setCalendarEndDate(endTime);
  }, [recordings]);

  //filter recordings by selected time period
  const filteredRecordings = recordings
    .filter((r) => {
      if (!endDate || !startDate) return true;

      const recordingDate = new Date(r.timestamp);
      return recordingDate.getTime() > startDate.getTime() && recordingDate.getTime() < endDate.getTime();
    })
    .filter((r) => {
      const recordingTime = new Date(r.timestamp);
      return recordingTime.getUTCHours() >= startTime && recordingTime.getUTCHours() < endTime;
    })
    .filter((r) => {
      if (filterTags === false && filteredTags.length === 0) return true;
      if (filterTags === true) {
        return (r.tags !== undefined);
      }

      if (r.tags === undefined) return false;

      const activeFilterTags =  filteredTags.map((tag: any) => tag.text);
      let match = false;

      r.tags.forEach((tag) => {
        match = match || (activeFilterTags.includes(tag.text));
      });

      return match;

    });

  //reset stack position after filtering
  useEffect(() => {
    if (!filteredRecordings.length) return;

    const startingTimestamp = timestamp || selectedTimestamp || '';
    const startIndex: number = getNearestTimestampIndex(filteredRecordings, startingTimestamp);
    gotoRecording(startIndex, startingTimestamp !== '');
  }, [startTime, endTime, startDate, endDate, recordings, timestamp]);

  const selectedRecordingIndex: number = getNearestTimestampIndex(filteredRecordings, selectedTimestamp);
  const selectedRecording: ConciseRecording = filteredRecordings[selectedRecordingIndex];
  const stackRef = useRef<HTMLDivElement>(null);

  const gotoRecording = (newIndex: number, noNavigrationUpdate?: boolean) => {
    if (newIndex === sliderIndex) {
      return;
    }
    setSliderIndex(newIndex);
    const timestamp = filteredRecordings[newIndex].timestamp
    setSelectedTimestamp(timestamp);
    if (noNavigrationUpdate) {
      return;
    }
    navigate(`/projects/${project.project}/${camera.cameraNumber}/${DateTimeFormatterIso(timestamp)}`);
  }

  const gotoPreviousRecording = () => {
    if (selectedRecordingIndex < filteredRecordings.length - 1) {
      gotoRecording(selectedRecordingIndex + 1);
    }
  };

  const gotoNextRecording = () => {
    if (selectedRecordingIndex > 0) {
      gotoRecording(selectedRecordingIndex - 1);
    }
  };

  const onSwiped = (e: SwipeEventData) => {
    if (e.dir === "Down") {
      gotoNextRecording();
    } else if (e.dir === "Up") {
      gotoPreviousRecording();
    }
  }
  const swipeHandler = useSwipeable({
    onSwiping: onSwiped,
  })

  // Update selected recording when scrolling in stack
  const handleWheel = (e: WheelEvent) => {
    e.preventDefault();

    if (!handleKeyActive) {
      return;
    }

    if (e.deltaY < 0) {
      gotoPreviousRecording();
    }

    if (e.deltaY > 0) {
      gotoNextRecording();
    }
  };

  // Update selected recording when scrolling in stack
  const handleKey = (e: KeyboardEvent) => {
    if (!handleKeyActive) {
      return;
    }

    if (e.key === "ArrowUp") {
      e.preventDefault();
      gotoPreviousRecording();
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      gotoNextRecording();
    }
  };

  // Bind wheel handler to ref object
  useEffect(() => {
    stackRef.current?.addEventListener("wheel", handleWheel, {passive: false});
    window.addEventListener("keydown", handleKey);

    return () => {
      stackRef.current?.removeEventListener("wheel", handleWheel);
      window.removeEventListener("keydown", handleKey);
    };
  });

  if (!recordings.length || !selectedTimestamp) {
    return <Pageloader></Pageloader>;
  }

  const zoomURL = `/projects/${project.project}/${camera.cameraNumber}/zoom`;
  const dailyStackUrl = `/projects/${project.project}/${camera.cameraNumber}/dailys`;

  const imageDownloadURL = filteredRecordings.length
    ? ImageUrlFormatter(
      project.project,
      camera.cameraNumber,
      selectedRecording.timestamp,
      isPtAdmin || project.allowImageSrcDownload ? "full" : "medium",
    )
    : "";
  const mediaDownloadName = filteredRecordings.length
    ? ImageDownloadStringFormatter(project.name, camera.name, selectedRecording.timestamp)
    : "";

  const navigateToZoom = () => navigate(zoomURL);

  const moveSlider = (e: number) => {
    // setSliderIndex(e);
  };

  const updateSlider = (e: number) => {
    gotoRecording(e);
  };

  const addCurrentToCompareImages = () => {
    addCompareImage(selectedRecordingIndex);
  };

  const updateRecording = (recording: ConciseRecording) => {
    setRecordings((prevRecordings) => {
      const updatedRecordings = [...prevRecordings];
      updatedRecordings[selectedRecordingIndex].tags = recording.tags;
      return updatedRecordings;
    });
  };

  return (
    <div ref={stackRef} className="container relative mx-auto sm:flex sm:justify-center gap-12 pt-8 pb-8">
      <CustomButton
        action={() => navigate(`/projects/${project.project}`)}
        buttonPositioning="absolute top-0 md:top-8 right-0 z-10"
        buttonPaddingClassName="p-1 m-1"
        iconProps={{
          icon: "XMarkIcon",
          iconClassName: "w-7 h-7",
        }}
        buttonBG="bg-panterra-800"
        tippyContent={t("tooltip:navigateBack")}
      ></CustomButton>

      <TagFilter
        filterTags={filterTags}
        setFilterTags={setFilterTags}
        filteredTags={filteredTags}
        setFilteredTags={setFilteredTags}
      />

      <Calendar startDate={calendarStartDate} endDate={calendarEndDate} showTime={true} />
      {!filteredRecordings.length && (
        <div className={"mt-24 text-center"}>
          <p>{t("stackFilterNoImagesFound")}</p>
        </div>
      )}

      {!!filteredRecordings.length && (
        <>
          {/*Slider*/}
          <div className="hidden w-32 flex-col items-center sm:flex">
            <div className="whitespace-nowrap px-2">
              {DateFormatter(filteredRecordings[filteredRecordings.length - 1].timestamp)}
            </div>
            <div className="flex-grow py-5">
              <ReactSlider
                className="flex h-full w-8 flex-col items-center"
                trackClassName="w-1 rounded-full cursor-pointer stackCustomSlider-track" //coloring requirescustomSilder-track to be last item in className
                thumbClassName="bg-panterra-600 cursor-grabbing text-white whitespace-nowrap px-2 rounded-md"
                onChange={(e) => moveSlider(e as number)}
                onAfterChange={(e) => updateSlider(e as number)}
                orientation="vertical"
                value={sliderIndex}
                invert={true}
                min={0}
                max={filteredRecordings.length - 1}
                step={1}
                renderThumb={(props, state) => (
                  <Tippy
                    key={"tippy"}
                    content={DateTimeFormatter(filteredRecordings[state.valueNow].timestamp)}
                    delay={[0, 1000]}
                    hideOnClick={false}
                    placement={"right"}
                  >
                    <div {...props}>{DateFormatter(filteredRecordings[state.valueNow].timestamp)}</div>
                  </Tippy>
                )}
              />
            </div>
            <div className="whitespace-nowrap px-2">{DateFormatter(filteredRecordings[0].timestamp)}</div>
          </div>

          {/*Stack*/}
          <div
            {...swipeHandler}
            style={{maxWidth: "calc(100vh - 80px"}}
            className={"flex flex-grow items-center outline-none"}
          >
            <div className={"mx-auto flex-grow"}>
              <div
                className="relative w-full [transform-style:preserve-3d] [perspective:60rem] [perspective-origin:top]"
                style={{aspectRatio: "100/85"}}
              >
                {filteredRecordings
                  .slice(selectedRecordingIndex, selectedRecordingIndex + numberOfStackImages)
                  .map((recording, index) => (
                    <div
                      key={recording.timestamp}
                      style={{
                        transform: `translate3d(0%, ${-index * 1.6}%, ${-index * 5}rem)`,
                        pointerEvents: index === 0 ? "auto" : "none",
                      }}
                      className="group absolute bottom-0 w-full"
                    >
                      <div className="absolute top-0 left-0 w-full h-4 text-xs">
                        {recording.tags &&
                          recording.tags.map((tag) => (
                            <span
                              key={tag.id}
                              className={`mx-1 px-1 bg-${tag.color}-500 rounded-md border border-panterra-500`}
                            >
                              {tag.text}
                            </span>
                          ))}
                      </div>
                      {index === 0 && (
                        <StackToolbar
                          type="image"
                          dailyStackUrl={dailyStackUrl}
                          imageZoomUrl={zoomURL}
                          mediaDownloadName={mediaDownloadName}
                          mediaDownloadUrl={imageDownloadURL}
                          addCurrentToCompareImages={addCurrentToCompareImages}
                        />
                      )}
                      {index === 0 && project.showPTLogoInTF && <ProjectBrandingPTLogo></ProjectBrandingPTLogo>}
                      {index === 0 && project.showClientLogoInTF && (
                        <ProjectBrandingClientLogo projectLogo={project.projectLogo}></ProjectBrandingClientLogo>
                      )}
                      <img
                        onDoubleClick={navigateToZoom}
                        className="w-full"
                        src={ImageUrlFormatter(project.project, camera.cameraNumber, recording.timestamp, "small")}
                        alt=""
                      />
                    </div>
                  ))}
              </div>

              {isPtAdmin && (
                <ImageFooter>
                  <ImageTag
                    recording={selectedRecording}
                    updateRecording={updateRecording}
                    keyboardActiveHandler={setHandleKeyActive}
                  />
                </ImageFooter>
              )}
              <ImageFooter>
                <span>{`${project.name} - ${camera.name}`}</span>
                <span>{DateTimeFormatter(filteredRecordings[selectedRecordingIndex].timestamp)}</span>
              </ImageFooter>
            </div>
          </div>

          {/* Horizintal slider for mobile*/}
          <div className="sm:hidden pt-6 pb-2">
            <HorizontalSlider
              sliderIndex={sliderIndex}
              stack={filteredRecordings}
              moveSlider={moveSlider}
              updateSlider={updateSlider}
            />
          </div>

          {/* Selected Images  */}
          <SelectedImages
            recordings={filteredRecordings}
            project={project.project}
            cameraNumber={camera.cameraNumber}
            compareImages={compareImages}
            showCompareImages={showCompareImages}
            clearCompareImages={clearCompareImages}
            removeCompareImage={removeCompareImage}
          />
        </>
      )}
    </div>
  );
};
